export const SiteInit = class {
    constructor(option = {}) {
        const defaultValue = {
            selector: {
                menuBtn: '#menuBtn',
                menu: '#menu',
                anchorIgnore: '.noscroll',
            },
            className: {
                menuBtnSwitch: 'on',
                menuSwitch: 'show',
            },
            callback: {
                anchor: () => { },
                menuOpen: () => { },
                menuClose: () => { },
            },
            anchorSpeed: 300,
            flags: null,
        };

        this.easing = function (t, b, c, d) { return c * (0.5 - Math.cos(t / d * Math.PI) / 2) + b; }; //jswing
        this.selector = { ...defaultValue.selector, ...option.selector };
        this.className = { ...defaultValue.className, ...option.className };
        this.callback = { ...defaultValue.callback, ...option.callback };
        this.anchorSpeed = (typeof option.anchorSpeed === 'undefined') ? defaultValue.anchorSpeed : option.anchorSpeed;
        this.flags = { ...defaultValue.flags, ...option.flags };

        this.scrollElm = (function () {
            if ('scrollingElement' in document) { return document.scrollingElement; }
            if (navigator.userAgent.indexOf('WebKit') != -1) { return document.body; }
            return document.documentElement;
        })();

        this.menuBtns = document.querySelectorAll(this.selector.menuBtn);
        this.menu = document.querySelector(this.selector.menu);

        const smoothScrollElm = document.querySelectorAll('a[href^="#"]:not(' + this.selector.anchorIgnore + ')');

        if (smoothScrollElm.length > 0) {
            for (let i = 0; i < smoothScrollElm.length; i++) {
                let elm = smoothScrollElm[i];
                elm.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.anchorScroll(elm.getAttribute('href'), this.callback.anchor);
                }, false);
            }
        }
        if (this.menuBtns) {
            this.menuBtns.forEach((el, i) => {
                el.addEventListener('click', () => { this.menuToggle(); }, false);
            });
        }
    }

    anchorScroll(href, cb) {
        let targetElm;
        if (typeof href === 'object') {
            targetElm = href;
        } else {
            targetElm = (href === '#') ? document.querySelector('html') : document.querySelector(href);
        }
        if (!targetElm) return;
        const startTime = Date.now();
        const scrollFrom = this.scrollElm.scrollTop;
        const duration = this.anchorSpeed;
        const loop = () => {
            const targetPos = targetElm.getBoundingClientRect().top + this.scrollElm.scrollTop - scrollFrom;
            const currentTime = Date.now() - startTime;
            if (currentTime < duration) {
                scrollTo(0, this.easing(currentTime, scrollFrom, targetPos, duration));
                window.requestAnimationFrame(loop);
            } else {
                scrollTo(0, targetPos + scrollFrom);
            }
        };
        loop();
        if (typeof cb === 'function') {
            cb();
        }
    }

    menuToggle() {
        if (this.menu.classList.contains(this.className.menuSwitch)) {
            this.menuClose();
        } else {
            this.menuOpen();
        }
    }

    menuOpen() {
        this.menu.classList.add(this.className.menuSwitch);
        this.menuBtns.forEach((el, i) => {
            el.classList.add(this.className.menuBtnSwitch);
        });
        this.callback.menuOpen();
    }

    menuClose() {
        this.menu.classList.remove(this.className.menuSwitch);
        this.menuBtns.forEach((el, i) => {
            el.classList.remove(this.className.menuBtnSwitch);
        });
        this.callback.menuClose();
    }

    setFlag(name, val) {
        this.flags[name] = val;
    }

    getFlag(name) {
        return this.flags[name];
    }
}
